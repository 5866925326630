
$(window).resize(function() {
    centeredPaginator();
    alignFooter(1);
});

$(document).ready(function() {
    $('select').material_select();
    $('.button-collapse').sideNav();
    $('.dropdown-button').dropdown();
    $('.notify_types').click(function () { changeNotifyType(this); });
    //initColorPickers();
    centeredPaginator();
    alignFooter(2);
    disabledDefaultSelectOption();
});

/*$('.color_picker').colorpicker().on('changeColor.color_picker', function(event){
    $(this).parent('div').find('i').css("color", event.color);
});*/

$(document).on('mousemove', '.popover__wrapper', function () {
    $(this).find('.popover__content').css('margin-top', '1.5rem');
});

$(document).ajaxComplete(function myErrorHandler(event, xhr) {
    if(xhr.status != 200){
        notify(true, xhr.status + ": " + xhr.statusText);
        hide_spinner();
    }
});

function disabledDefaultSelectOption() {
    $("select [value='']").attr('disabled', 'disabled')
}

/*function initColorPickers() {
    $('.color_picker').map(function (index, el) {
        $(el).colorpicker({input: 'input'});
        $(el).parent('div').find('i').css("color", $(el).parent('div').find('input').val());
    });
}*/

function changeNotifyType(_self) {
    $('.notify_type').val('');
    if($(_self).prop("checked")){
        $('.notify_type').val($(_self).attr("data-type"));
        $('.notify_types').prop("checked", false);
        $(_self).prop("checked", true);
    }
}

function centeredPaginator() {
    var width = 0;
    $('.pagination').first().find('li').map(function (el, li) { width =  width + li.clientWidth; });
    $('.pagination').css('width', width + 1).css('margin-left', ($('.pagination').parent().width() - width) / 2);
}

function redirect_without(keys) {
    var url = window.location.href;
    for (var k = 0; k < keys.length; k = k + 1) {
        var key = keys[k];
        var rtn = url.split("?")[0], param, params_arr = [], queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {params_arr.splice(i, 1);}
            }
            url = rtn + "?" + params_arr.join("&");
        }
    }
    window.location.href = url;
}

function refreshUrlByHref(el) {document.location.href = $(el).attr('href');}
function toUCF(value) { return value.charAt(0).toUpperCase() + value.slice(1); }
function show_spinner() { $('.spinner-loader').removeClass('hide'); }
function hide_spinner() { $('.spinner-loader').addClass('hide'); }
function getCsrfToken() { return $('meta[name="csrf-token"]').attr('content'); }
function alignFooter(milisec){setTimeout(function(){window.innerHeight > $('body').height() ? $('.page-footer').addClass('alignFooter') : $('.page-footer').removeClass('alignFooter');}, milisec);}

 /**
  *
  *   app notifications
  *
 **/
function notify(error, message) {
    var block = $('body').find(".notification-block").find('div.row').clone();
    $(block).find(".notification-message").text(message);
    $(block).find(".notification-error-type").addClass((error ? "red" : "green"));
    $(block).find(".notification-error-type").addClass("card-panel-" + (error ? "error" : "success"));
    error ? $(block).find(".notification-error-title").removeClass("hide") : null;
    return block;
}

function notifyToEl(el, error, message) {
    $(el).append(notify(error, message));
    var hide_el = setTimeout(function(){
        var opacity = 1;
        var opacity_el = setInterval(function(){
            opacity -= 0.1;
            $(el).css('opacity', opacity);
            if(opacity <= 0){
                $(el).css('opacity', 1).html('');
                clearTimeout(hide_el);
                clearInterval(opacity_el);
            }
        }, 70);
    }, 5000);

}
